export const STATUS_DRAFT = "DRAFT";
export const STATUS_PENDING = "PENDING"
export const STATUS_COMPLETED = "COMPLETED";
export const STATUS_CANCELED = "CANCELED";

export const MOTIF_VEHICLE_DAMAGED = "VEHICLE_DAMAGED";
export const MOTIF_INVALID_PAYMENT = "INVALID_PAYMENT"
export const MOTIF_OTHER = "OTHER";

export const MOTIF_END_ACCEPTED = "ACCEPTED";
export const MOTIF_END_REFUSED = "REFUSED";
export const MOTIF_END_ACCEPTED_SELECT = "ACCEPTED";
export const MOTIF_END_REFUSED_SELECT = "REFUSED";

export const RECLAMATIONS_STATUS = [
  STATUS_DRAFT,
  STATUS_PENDING,
  STATUS_COMPLETED,
  STATUS_CANCELED,
];

export const RECLAMATIONS_MOTIFS = [
  MOTIF_VEHICLE_DAMAGED,
  MOTIF_INVALID_PAYMENT,
  MOTIF_OTHER,
];

export const RECLAMATIONS_MOTIFS_ENDS = [
  MOTIF_END_ACCEPTED,
  MOTIF_END_REFUSED,
];
export const RECLAMATIONS_MOTIFS_ENDS_SELECT = [
  MOTIF_END_ACCEPTED_SELECT,
  MOTIF_END_REFUSED_SELECT,
];