import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/reclamations`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/reclamations/${id}?include=organization,location,transaction`, options)
    .then((response) => {
      let reclamation = jsona.deserialize(response.data);
      delete reclamation.links;
      return reclamation;
    });
}

function add(reclamation) {
  const payload = jsona.serialize({
    stuff: reclamation,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.post(`${url}/reclamations`, payload, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}

function update(reclamation) {
  const payload = jsona.serialize({
    stuff: reclamation,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/reclamations/${reclamation.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/reclamations/${id}`, options);
}

function complete(reclamation) {
  const payload = jsona.serialize({
    stuff: reclamation,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/reclamations/${reclamation.id}/complete`, payload, options)
    .then((response) => {
      let reclamation = jsona.deserialize(response.data);
      delete reclamation.links;
      return reclamation;
    });
}

function cancel(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/reclamations/${id}/cancel?include=organization,location,transaction`,
      options
    )
    .then((response) => {
      let reclamation = jsona.deserialize(response.data);
      delete reclamation.links;
      return reclamation;
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  complete,
  cancel,
};
