import service from "@/store/services/contracts-service";

export const state = () => ({
  list: {},
  contract: {},
  meta: {},
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, contract) => {
    state.contract = contract;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((contract) => {
      commit("SET_RESOURCE", contract);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((contract) => {
      commit("SET_RESOURCE", contract);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((contract) => {
      commit("SET_RESOURCE", contract);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  contract: (state) => state.contract,
  url: (state) => state.url,
  dropdown: (state) => {
    return state.list.map((contract) => ({
      id: contract.id,
      name: `${contract.name}`,
    }));
  },
};

const contracts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default contracts;
