export default {
  CONTACT_FORM_LIST: 'Liste des formulaires de contact',
  ADD_CONTACT_FORM: 'Ajouter un formulaire de contact',
  CONTACT_FORM_ADDED: 'Formulaire de contact ajouté',
  EDIT_CONTACT_FORM: 'Modifier un formulaire de contact',
  CONTACT_FORM_UPDATED: 'Formulaire de contact modifié',
  DELETE_THIS_CONTACT_FORM: 'Supprimer ce formulaire de contact?',
  CONTACT_FORM_DELETED: 'Formulaire de contact supprimé',
  VIEW_CONTACT_FORM: 'Formulaire de contact',
  CONTACT_FORMS_LIST: 'Liste des formulaires de contact',
  SUBSCRIBE_TO_NEWSLETTER: 'S\'abonner au newsletter',
  SUBSCRIBED_TO_NEWSLETTER: 'Abonné au newsletter',
}