import permissions from "../constants/permissions";
import AdminModuleLayout from "@/views/Pages/AdminModule/AdminModuleLayout.vue";
import AdminModuleMenu from "@/views/Pages/AdminModule/AdminModuleMenu.vue";

//import middleware
import authorized from "@/middleware/authorized";
import authenticated from "@/middleware/authenticated";

//import pages
const AdminPage = () => import("@/views/Pages/AdminModule/AdminPage.vue");

const ListRolePage = () =>
  import("@/views/Pages/AdminModule/RoleManagement/ListRolePage.vue");

const ListUserPage = () =>
  import("@/views/Pages/AdminModule/UserManagement/ListUserPage.vue");

const ListOrganizationPage = () =>
  import(
    "@/views/Pages/AdminModule/OrganizationManagement/ListOrganizationPage.vue"
  );

const ListOrganizationGroupsPage = () =>
  import(
    "@/views/Pages/AdminModule/OrganizationGroupsManagement/ListOrganizationGroupsPage.vue"
  );

const ListResellerPage = () =>
  import("@/views/Pages/AdminModule/ResellerManagement/ListResellerPage.vue");

const ListLocationPage = () =>
  import("@/views/Pages/AdminModule/LocationManagement/ListLocationPage.vue");

const ListImportPage = () =>
  import("@/views/Pages/AdminModule/ImportModule/ListImportPage.vue");
/* const AddImportPage = () =>
  import("@/views/Pages/AdminModule/ImportModule/AddImport.vue");
const EditImportPage = () =>
  import("@/views/Pages/AdminModule/ImportModule/EditImportPage.vue");
const ViewImportPage = () =>
  import("@/views/Pages/AdminModule/ImportModule/ViewImportPage.vue"); */
const ListInvitationPage = () =>
  import(
    "@/views/Pages/AdminModule/InvitationManagement/ListInvitationPage.vue"
  );

const ListLabelPage = () =>
  import("@/views/Pages/AdminModule/LabelManagement/ListLabelPage.vue");

const ListChargePackagePage = () =>
  import(
    "@/views/Pages/AdminModule/ChargePackageManagement/ListChargePackagePage.vue"
  );


const ListContractPage = () =>
  import(
    "@/views/Pages/AdminModule/ContractManagement/ListContractPage.vue"
  );

  const ListPaymentMethodPage = () =>
    import(
      "@/views/Pages/AdminModule/PaymentMethodManagement/ListPaymentMethodPage.vue"
    );
  
const ListChargePage = () =>
  import("@/views/Pages/AdminModule/ChargeManagement/ListChargePage.vue");

const ListTemplateMailPage = () =>
  import("@/views/Pages/AdminModule/TemplateMailManagement/ListTemplateMailPage.vue");


const ListContactFormPage = () =>
  import("@/views/Pages/AdminModule/ContactFormManagement/ListContactFormPage.vue");

const ListSignaturePage = () =>
  import(
    "@/views/Pages/AdminModule/SignatureManagement/ListSignaturePage.vue"
  );

let labelManagementPages = {
  path: "labels",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Labels",
      components: { default: ListLabelPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_LABELS,
        hasLevel2: true,
      },
    },
  ],
};

let chargePackageManagementPages = {
  path: "charge-packages",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List ChargePackages",
      components: { default: ListChargePackagePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CHARGE_PACKAGES,
        hasLevel2: true,
      },
    },
  ],
};

let contractManagementPages = {
  path: "contracts",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Contracts",
      components: { default: ListContractPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CONTRACTS,
        hasLevel2: true,
      },
    },
  ],
};


let signatureManagementPages = {
  path: "signatures",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Signatures",
      components: { default: ListSignaturePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SIGNATURES,
        hasLevel2: true,
      },
    },
  ],
};


let paymentMethodManagementPages = {
  path: "payment-methods",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List PaymentMethods",
      components: { default: ListPaymentMethodPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CHARGE_PACKAGES,
        hasLevel2: true,
      },
    },
  ],
};



let chargeManagementPages = {
  path: "charges",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Charges",
      components: { default: ListChargePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CHARGES,
        hasLevel2: true,
      },
    },
  ],
};

let userManagementPages = {
  path: "users",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Users",
      components: { default: ListUserPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_USERS,
        hasLevel2: true,
      },
    },
  ],
};

let roleManagementPages = {
  path: "roles",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Roles",
      components: { default: ListRolePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_ROLES,
        hasLevel2: true,
      },
    },
  ],
};

let resellerManagementPages = {
  path: "resellers",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Resellers",
      components: { default: ListResellerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_RESELLERS,
        hasLevel2: true,
      },
    },
  ],
};

let organizationManagementPages = {
  path: "organizations",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Organizations",
      components: { default: ListOrganizationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_ORGANIZATIONS,
        hasLevel2: true,
      },
    },
  ],
};

let organizationGroupsManagementPages = {
  path: "organization-group",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Organizations Groups",
      components: { default: ListOrganizationGroupsPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_ORGANIZATIONS,
        hasLevel2: true,
      },
    },
  ],
};

let locationsManagementPages = {
  path: "locations",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Locations",
      components: { default: ListLocationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_LOCATIONS,
        hasLevel2: true,
      },
    },
  ],
};

let importManagementPages = {
  path: "imports",
  component: AdminModuleLayout,
  children: [
    {
      path: "",
      name: "List Imports",
      components: { default: ListImportPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_IMPORTS,
      },
    },
    /* {
      path: "add",
      name: "Add Imports",
      components: { default: AddImportPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_IMPORTS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Imports",
      components: { default: EditImportPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_IMPORTS,
      },
    },
    {
      path: "view/:id",
      name: "View Imports",
      components: { default: ViewImportPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_IMPORTS,
      },
    }, */
  ],
};
let InvitationManagementPages = {
  path: "invitations",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Invitations",
      components: { default: ListInvitationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_IMPORTS,
        hasLevel2: true,
      },
    },
  ],
};


let templatesMailsManagementPages = {
  path: "templates-mails",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List TemplatesMails",
      components: { default: ListTemplateMailPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_TEMPLATES_MAILS,
        hasLevel2: true,
      },
    },
  ],
};

let contactFormsManagementPages = {
  path: "contact-forms",
  components: {
    default: AdminModuleLayout,
    SidebarSecondLevelMenu: AdminModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List ContactForms",
      components: { default: ListContactFormPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_TEMPLATES_MAILS,
        hasLevel2: true,
      },
    },
  ],
};

const adminModuleRoutes = [
  {
    path: "",
    components: {
      default: AdminModuleLayout,
      SidebarSecondLevelMenu: AdminModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Admin Page",
        components: { default: AdminPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  userManagementPages,
  roleManagementPages,
  organizationManagementPages,
  organizationGroupsManagementPages,
  locationsManagementPages,
  resellerManagementPages,
  importManagementPages,
  InvitationManagementPages,
  labelManagementPages,
  chargeManagementPages,
  contractManagementPages,
  chargePackageManagementPages,
  templatesMailsManagementPages,
  contactFormsManagementPages,
  paymentMethodManagementPages,
  signatureManagementPages,
];

export default adminModuleRoutes;
