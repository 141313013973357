import permissions from "../constants/permissions";
import AuctionsModuleLayout from "@/views/Pages/AuctionsModule/AuctionsModuleLayout.vue";
import AuctionsModuleMenu from "@/views/Pages/AuctionsModule/AuctionsModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const AuctionsPage = () =>
  import("@/views/Pages/AuctionsModule/AuctionsPage.vue");

const ListPropertyPage = () =>
  import(
    "@/views/Pages/AuctionsModule/PropertyManagement/ListPropertyPage.vue"
  );

const ListAuctionPage = () =>
  import("@/views/Pages/AuctionsModule/AuctionManagement/ListAuctionPage.vue");

const ListVehiclePage = () =>
  import(
    /*     "@/views/Pages/AuctionsModule/VehicleManagement/ListVehiclePage.vue" */
    "@/views/Pages/AuctionsModule/VehicleManagement/ListVehiclePage.vue"
  );

const ListTransactionPage = () =>
  import(
    "@/views/Pages/AuctionsModule/TransactionManagement/ListTransactionPage.vue"
  );
const CalendarEventPage = () =>
  import("@/views/Pages/AuctionsModule/EventManagement/CalendarEventPage.vue");

const ListCollectePage = () =>
  import(
    "@/views/Pages/AuctionsModule/CollecteManagement/ListCollectePage.vue"
  );

const ListReclamationPage = () =>
  import(
    "@/views/Pages/AuctionsModule/ReclamationManagement/ListReclamationPage.vue"
  );

let propertyManagementPages = {
  path: "properties",
  components: {
    default: AuctionsModuleLayout,
    SidebarSecondLevelMenu: AuctionsModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Properties",
      components: { default: ListPropertyPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PROPERTIES,
      },
    },
  ],
};

let auctionManagementPages = {
  path: "auctions",
  components: {
    default: AuctionsModuleLayout,
    SidebarSecondLevelMenu: AuctionsModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Auctions",
      components: { default: ListAuctionPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_AUCTIONS,
      },
    },
  ],
};

let vehicleManagementPages = {
  path: "vehicles",
  components: {
    default: AuctionsModuleLayout,
    SidebarSecondLevelMenu: AuctionsModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Vehicles",
      components: { default: ListVehiclePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_VEHICLES,
      },
    },
  ],
};

let eventManagementPages = {
  path: "events",
  components: {
    default: AuctionsModuleLayout,
    SidebarSecondLevelMenu: AuctionsModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Events",
      components: { default: CalendarEventPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_EVENTS,
        hasLevel2: true,
      },
    },
  ],
};

let transactionManagementPages = {
  path: "transactions",
  components: {
    default: AuctionsModuleLayout,
    SidebarSecondLevelMenu: AuctionsModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Transactions",
      components: { default: ListTransactionPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_TRANSACTIONS,
      },
    },
  ],
};

let collecteManagementPages = {
  path: "collectes",
  components: {
    default: AuctionsModuleLayout,
    SidebarSecondLevelMenu: AuctionsModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Collectes",
      components: { default: ListCollectePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_COLLECTES,
      },
    },
  ],
};

let reclamationManagementPages = {
  path: "reclamations",
  components: {
    default: AuctionsModuleLayout,
    SidebarSecondLevelMenu: AuctionsModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Reclamations",
      components: { default: ListReclamationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_RECLAMATIONS,
      },
    },
  ],
};

const AuctionModuleRoutes = [
  {
    path: "",
    components: {
      default: AuctionsModuleLayout,
      SidebarSecondLevelMenu: AuctionsModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Auctions Page",
        components: { default: AuctionsPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  propertyManagementPages,
  auctionManagementPages,
  vehicleManagementPages,
  transactionManagementPages,
  eventManagementPages,
  collecteManagementPages,
  reclamationManagementPages,
];

export default AuctionModuleRoutes;
