export default {
  SIGNATURES: "Signatures",
  SIGNATURES_LIST: "Liste des signatures",
  ADD_SIGNATURE: "Ajouter une signature",
  SIGNATURE_ADDED: "Signature ajoutée",
  EDIT_SIGNATURE: "Modifier une signature",
  SIGNATURE_UPDATED: "Signature mise à jour",
  DELETE_THIS_SIGNATURE: "Supprimer cette signature ?",
  SIGNATURE_DELETED: "Signature supprimée",
  SIGNATURE_TYPE: "Catégorie de la signature",
  CATEGORY: "Catégorie",
  VIEW_SIGNATURE: "Signature",
  RETURN_TO_FROM: "Retourner au formulaire",
  SAME_SIGNATURES:
    'Une ou plusieures signature ont déjà été enregistrées avec le nom {name}. S\'agit-il de la même signature ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
  CREATED_AT: "Créée le",
  SELECT: "Sélectionner une signature",
  DPA: "Dépôt directe",
  PPA: "Paiement préautorisé",
  DPA_PPA: "Débit et crédit préautorisés",
  OTHERS: "Autres",
  TYPE_REQUIRED: "Veuillez sélectionner une catégorie",
  FILES: "Documents",

  EMAIL_MESSAGE: "Message de l'e-mail de signature",
  CONTRACT_REQUIRED: "Veuillez sélectionner un contrat",
  SELECT_CONTRACT: "Sélectionnez un contrat",
  CREATED: "Créee",
  UPDATED: "Mise à jour",
  DELETED: "Supprimée",
  DRAFT: "Brouillon",
  SENT: "Envoyée",
  COMPLETED: "Complétée",
  DECLINED: "Refusée",
  EXPIRED: "Expirée",
  SIGNERS: "Signataires",
  SIGNERS_REQUIERED: "Veuillez ajouter au moin un signataire",
  SEND: "Envoyer",
  SEND_FOR_SIGN_MESSAGE: "Confirmez-vous vouloir envoyer cette demande aux différents signataires ?",
  SIGNATURE_SENT: "Demande de signature envoyée !"
};
