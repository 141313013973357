import service from "@/store/services/contract-files-service";

export const state = () => ({
  list: {},
  file: {},
  meta: {},
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, file) => {
    state.file = file;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  upload({ commit }, { image, ressource_name, field, ressouce_id = 0 }) {
    return service
      .upload(image, ressource_name, ressouce_id, field, this.$axios)
      .then((url) => {
        commit("SET_URL", url);
      });
  },

  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((file) => {
      commit("SET_RESOURCE", file);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((file) => {
      commit("SET_RESOURCE", file);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((file) => {
      commit("SET_RESOURCE", file);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  file: (state) => state.file,
  url: (state) => state.url,
};

const contractFiles = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default contractFiles;
