export default {
    CONTRACTS: "Contrats",
    CONTRACTS_LIST: "Liste des contrats",
    ADD_CONTRACT: "Ajouter un contrat",
    CONTRACT_ADDED: "Contrat ajouté",
    EDIT_CONTRACT: "Modifier un contrat",
    CONTRACT_UPDATED: "Contrat mis à jour",
    DELETE_THIS_CONTRACT: "Supprimer ce contrat ?",
    CONTRACT_DELETED: "Contrat supprimé",
    CONTRACT_TYPE: "Catégorie du contrat",
    CATEGORY: "Catégorie",
    VIEW_CONTRACT: "Contrat",
    RETURN_TO_FROM: "Retourner au formulaire",
    SAME_CONTRACTS:
      'Un ou plusieurs contracts ont déjà été enregistrés avec le nom {name}. S\'agit-il du même contrat ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
    CREATED_AT: 'Créé le',
    SELECT:"Sélectionner un contrat",
    DPA:"Dépôt directe",
    PPA:"Paiement préautorisé",
    DPA_PPA: "Débit et crédit préautorisés",
    OTHERS: "Autres",
    TYPE_REQUIRED:"Veuillez sélectionner une catégorie",
    FILES:"Documents",
    ADD_FILE:"Ajouter un document",
    ADD_FILES:"Ajouter les documents",
    DELETE_THIS_FILE:"Supprimer ce document ?",
    FILE_DELETED:"Document supprimé !",
    SIGNATURE_PAGE:"Page de signature",
    NAME_REQUIRED:"Veuillez entrer le nom du document",
    SIGNATURE_PAGE:'Page de signature',
    SIGNATURE_PAGE_REQUIRED:'Le numéro de la page de signature est requis',
    EDIT_FILE:"Modifier le document",
    ADD_TEMPLATE_DOCUMENT: "Ajouter un modèle de document (Template)",
    TEMPLATE_NAME:"Modèle (vue)",
    TEMPLATE_NAME_REQUIRED: "Le nom du modèle est requis",
    FILTER_CONTRACTS: "Filtrer les contrats",
  };
  