import {
  STATUS_DRAFT,
  STATUS_PENDING,
  STATUS_INVOICED,
  STATUS_PAIED,
  STATUS_DELIVERED,
  STATUS_CANCELED,
  STATUS_VALIDATED,
  DPA,
  CHECK,
} from "../../constants/transactions";
export default {
  TRANSACTIONS_LIST: "Liste des transactions",
  ADD_TRANSACTION: "Ajouter une transaction",
  VIEW_TRANSACTION: "Transaction",
  TRANSACTION_ADDED: "Transaction ajoutée",
  EDIT_TRANSACTION: "Modifier une transaction",
  TRANSACTION_UPDATED: "Transaction mise à jour",
  DELETE_THIS_TRANSACTION: "Supprimer cette transaction ?",
  TRANSACTION_DELETED: "Transaction supprimée",
  VALIDATE_TRANSACTION: "Valider une transaction",
  TRANSACTION_VALIDATED: "Transaction validée",
  STATUS: "Statut",
  PRICE: "Prix",
  QTE: "Qte",
  TOTAL: "Total",
  VEHICLE: "Véhicule",
  ORGANIZATION_SELLER: "Organisation vendeur",
  ORGANIZATION_BUYER: "Organisation acheteur",
  LOCATION_SELLER: "Concession vendeur",
  LOCATION_BUYER: "Concession acheteur",
  SELLER_PAYMENT_METHOD: "Méthode de paiement (vendeur)",
  SELLER_SHIPPING_ADDRESS: "Adresse de livraison (vendeur)",
  SELLER_SHIPPING_METHOD: "Méthode de livraison (vendeur)",
  BUYER_PAYMENT_METHOD: "Méthode de paiement (acheteur)",
  BUYER_SHIPPING_ADDRESS: "Adresse de livraison (acheteur)",
  BUYER_SHIPPING_METHOD: "Méthode de livraison (acheteur)",
  CANCEL_TRANSACTION: "Annuler",
  CANCEL_TRANSACTION_QUESTION: "Annuler la transaction ?",
  TRANSACTION_CANCELED: "Transaction annulée",
  BUYER_INVOICE: "Facture acheteur",
  SELLER_INVOICE: "Facture vendeur",
  ALL_STATUS: "Tous les statuts",
  VIEW_TRANSACTION: "Transaction",
  STATUS_DRAFT : "Brouillon",
  STATUS_PENDING : "En traitement",
  STATUS_INVOICED : "Facturée",
  STATUS_PAIED : "Payée",
  STATUS_DELIVERED : "Livrée",
  STATUS_CANCELED : "Annulée",
  STATUS_VALIDATED : "Validée",
  DPA: "DPA",
  CHECK: "Chéque",
};
