import {
  STATUS_DRAFT,
  STATUS_PENDING,
  STATUS_COMPLETED,
  STATUS_CANCELED,
  MOTIF_VEHICLE_DAMAGED,
  MOTIF_INVALID_PAYMENT,
  MOTIF_OTHER,
} from "../../constants/reclamations";
export default {
  RECLAMATIONS_LIST: "Liste des réclamations",
  ADD_RECLAMATION: "Ajouter une réclamation",
  VIEW_RECLAMATION: "Réclamation",
  RECLAMATION_ADDED: "Réclamation ajoutée",
  EDIT_RECLAMATION: "Modifier une réclamation",
  RECLAMATION_UPDATED: "Réclamation mise à jour",
  DELETE_THIS_RECLAMATION: "Supprimer cette réclamation ?",
  RECLAMATION_DELETED: "Réclamation supprimée",
  STATUS: "Statut",
  MOTIF: "Motif",
  OTHER_MOTIF: "Autre motif",
  EXCERPT: "Déscription",
  ATTACHEMENTS: "Pièces jointes",
  CANCEL_RECLAMATION: "Annuler",
  CANCEL_RECLAMATION_QUESTION: "Annuler la réclamation ?",
  COMPLETE_RECLAMATION: "Terminer",
  COMPLETE_RECLAMATION_QUESTION: "Terminer la réclamation ?",
  RECLAMATION_CANCELED: "Réclamation annulée",
  RECLAMATION_COMPLETED: "Réclamation terminée",
  ALL_STATUS: "Tous les statuts",
  VIEW_RECLAMATION: "Réclamation",
  STATUS_DRAFT : "Brouillon",
  STATUS_PENDING : "En traitement",
  STATUS_COMPLETED : "Terminée",
  STATUS_CANCELED : "Annulée",
  MOTIF_VEHICLE_DAMAGED : "Véhicule endommagé",
  MOTIF_INVALID_PAYMENT : "Paiement non valide",
  MOTIF_OTHER : "Autre",
  MOTIF_END_ACCEPTED_SELECT : "Accepter",
  MOTIF_END_REFUSED_SELECT : "Refuser",
  MOTIF_END_ACCEPTED : "Acceptée",
  MOTIF_END_REFUSED : "Refusée",
  DESCRIPTION_END : "Raison",
};
